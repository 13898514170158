import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import '../components/scss/landing_pages/landing_page_subscriber_benefits.scss'
import Content, { HTMLContent } from '../components/Content'
import { Helmet } from 'react-helmet'
import TermsGrid from "../components/Terms";
import Benefits from "../components/Benefits";
import useSiteMetadata from "../components/SiteMetadata";
import Header from "../components/Header";

export const LandingPageSubscriberBenefitsTemplate = ({
  title,
  offer,
  benefits,
  main_heading,
  main_footer,
  content,
  contentComponent,
  helmet,
}) => {
  const PageContent = contentComponent || Content

  return (
    <div className="landing-page landing-page--subscriber-benefits">
      <Header />

      <div className="content">
        <div className="content__inner">
          {helmet || ''}
          <div
            className="page-header"
          >
            <div className="columns page-header__top">
              <div className="column is-two-thirds">
              <h1
                className="page-title is-size-1 mt-0 mb-0"
              >
                {title}
              </h1>
              </div>
              <div className="column has-text-right-desktop">
                <a href="https://www.nationalobserver.com/subscribe" title="Subscribe to Canada's National Observer" className="button is-medium is-rounded is-primary is-size-4 has-text-weight-bold">
                  Subscribe
                </a>
              </div>
            </div>
            <div className="columns page-header__bottom">
              <div className="column is-full pt-0">
                <h3 className="has-text-weight-normal is-size-4 mt-0 mb-0">{offer.heading}</h3>
              </div>
            </div>
            <TermsGrid gridItems={offer.terms} />
          </div>
          <section className="section page-main px-0">
            <div className="container">
              <div className="columns">
                <div className="column is-12">
                  <div className="columns">
                    <div className="column is-7">
                      <h3 className="has-text-weight-bold is-size-4">
                        {main_heading}
                      </h3>
                      <section>
                        <PageContent className="content" content={content} />
                      </section>
                      <section className="section px-0">
                        <div className="mb-6 has-text-primary has-text-weight-bold">{main_footer}</div>
                        <a href="https://www.nationalobserver.com/subscribe" title="Subscribe to Canada's National Observer" className="button is-medium is-rounded is-primary is-size-4 has-text-weight-bold">
                          Subscribe
                        </a>
                      </section>
                    </div>
                    <div className="column is-4 is-offset-1">
                      <Benefits gridItems={benefits} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

LandingPageSubscriberBenefitsTemplate.propTypes = {
  contentComponent: PropTypes.func,
  content: PropTypes.string,
  title: PropTypes.string.isRequired,
  offer: PropTypes.shape({
    heading: PropTypes.string,
    terms: PropTypes.array,
  }),
  benefits: PropTypes.array,
  main_heading: PropTypes.string,
  helmet: PropTypes.object,
}

const LandingPageSubscriberBenefits = ({ data, location }) => {
  const { markdownRemark: page } = data
  const { siteURL } = useSiteMetadata()

  return (
      <Layout location={location}>
        <LandingPageSubscriberBenefitsTemplate
            contentComponent={HTMLContent}
            title={page.frontmatter.title}
            offer={page.frontmatter.offer}
            benefits={page.frontmatter.benefits}
            main_heading={page.frontmatter.main_heading}
            main_footer={page.frontmatter.main_footer}
            content={page.html}
            helmet={
              <Helmet>
                <meta name="description" content="Canada's National Observer is excited to announce new subscription packages. Find out more." />
                <meta property="og:title" content={page.frontmatter.title} />
                <meta name="og:description" content="Canada's National Observer is excited to announce new subscription packages. Find out more." />
                <meta property="og:image" content={`${siteURL}/img/og-image.jpg`} />
                <meta name="twitter:title" content={page.frontmatter.title} />
                <meta name="twitter:description" content="Canada's National Observer is excited to announce new subscription packages. Find out more." />
                <meta name="twitter:image" content={`${siteURL}/img/og-image.jpg`} />
              </Helmet>
            }
        />
      </Layout>
  )
}

LandingPageSubscriberBenefits.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default LandingPageSubscriberBenefits

export const LandingPageSubscriberBenefitsQuery = graphql`
  query LandingPageSubscriberBenefits($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        offer {
          heading
          terms {
            name
            price
          }
        }
        benefits {
          heading
          price
          list {
            benefit
          }
        }
        main_heading
        main_footer
      }
    }
  }
`
