import React from 'react'
import './scss/site_header.scss'
import logo from "../img/logo.svg";

const Header = () => {
  return (
    <header className="site-header">
      <div className="site-header__inner">
        <div className="logo">
          <a href="https://www.nationalobserver.com" title="Canada's National Observer" style={{display: 'block', width: '270px', height: '100px', maxWidth: '50%'}}>
            <img src={logo} alt="Canada's National Observer" style={{width: '270px', height: '100px' }} />
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header
