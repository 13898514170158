import React from 'react'
import PropTypes from 'prop-types'
import "./scss/benefits.scss"

const Benefits = ({ gridItems }) => (
  <div className="benefits">
    {gridItems.map((item) => (
      <div key={item.heading}>
        <section className="mb-6">
          <h3 className="has-text-primary is-size-4 mb-0">{item.heading}</h3>
          <div className="has-text-weight-bold is-size-4">{item.price}</div>
          <ul className="mt-1">
            {item.list.map((list_item) => (
              <li className="mb-2">{list_item.benefit}</li>
            ))}
          </ul>
        </section>
      </div>
    ))}
  </div>
)

Benefits.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      price: PropTypes.string,
      benefits: PropTypes.string,
    })
  ),
}

export default Benefits
