import React from 'react'
import PropTypes from 'prop-types'
import './scss/terms.scss'

const TermsGrid = ({ gridItems }) => (
  <div className="columns terms">
    {gridItems.map((item) => (
      <div key={item.name} className="column">
        <a href={`https://www.nationalobserver.com/subscribe?utm_source=benefits&utm_medium=static_page&utm_campaign=regular&utm_term=${item.name}`.toLowerCase().replace(' ', '_')}>
          <section className="term has-background-black has-text-centered">
            <h3 className="has-text-primary is-size-4 mt-0 mb-0">{item.name}</h3>
            <p className="has-text-white is-size-4">{item.price}</p>
          </section>
        </a>
      </div>
    ))}
  </div>
)

TermsGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.string,
    })
  ),
}

export default TermsGrid
